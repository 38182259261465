class ZebraPrinter {
    static instance = null;
    constructor() {
        this.device = null;
        this.characteristic = null;
        this.isConnected = false;
    }

    static getInstance() {
        if (!ZebraPrinter.instance) {
            ZebraPrinter.instance = new ZebraPrinter();
        }
        return ZebraPrinter.instance;
    }

    async connect() {
        try {
            this.device = await navigator.bluetooth.requestDevice({
                filters: [{ namePrefix: 'XXZLN' }],
                optionalServices: [
                    '38eb4a80-c570-11e3-9507-0002a5d5c51b',
                    '000018f0-0000-1000-8000-00805f9b34fb',
                    'e7810a71-73ae-499d-8c15-faa9aef0c3f2'
                ]
            });

            const server = await this.device.gatt.connect();
            const services = await server.getPrimaryServices();

            for (const service of services) {
                const characteristics = await service.getCharacteristics();
                const writeCharacteristic = characteristics.find(c => c.properties.write);
                if (writeCharacteristic) {
                    this.characteristic = writeCharacteristic;
                    this.isConnected = true;
                    return true;
                }
            }
            throw new Error('No writable characteristic found');
        } catch (error) {
            console.error('Connection failed:', error);
            this.isConnected = false;
            return false;
        }
    }

    async ensureConnected() {
        if (!this.isConnected || !this.device?.gatt?.connected) {
            return await this.connect();
        }
        return true;
    }

    disconnect() {
        if (this.device?.gatt?.connected) {
            this.device.gatt.disconnect();
            this.isConnected = false;
        }
    }
}

export function calculateBarcodeOffset(text, printWidth = 406, moduleWidth = 14, byValue = 2) {
    const barcodeWidth = text.length * moduleWidth * byValue;
    const offset = Math.floor((printWidth - barcodeWidth) / 2);
    return offset > 0 ? offset : 0;
}

export async function printZpl(zplContent) {
    const printer = ZebraPrinter.getInstance();
    
    try {
        const connected = await printer.ensureConnected();
        if (!connected) {
            throw new Error('Failed to connect to printer');
        }

        const encoder = new TextEncoder();
        const data = encoder.encode(zplContent);
        await printer.characteristic.writeValue(data);
        return true;
    } catch (error) {
        console.error('Print failed:', error);
        return false;
    }
}